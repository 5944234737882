import { createContext } from "react";
import swal from "sweetalert";
import moment from "moment";

// export const weburl = "https://staging-cultivator.semilla.app/"
// export const apiUrl = "https://staging-api.semilla.app"
// export const baseUrl = "https://staging-api.semilla.app/"
// export const socketUrl = "wss://staging-api.semilla.app/b
// oot-stomp"

// local 
//  export const weburl = "http://localhost:3000/"
//  export const apiUrl = "http://localhost:8081"  
//  export const baseUrlOld = "https://api.semilla.app/"
//  export const baseUrl = "http://localhost:8081/"
//  export const socketUrl = "ws://localhost:8081/boot-stomp"

  export const weburl = "https://semillaapp.com/"
  export const apiUrl = "https://api.semilla.app"
  export const baseUrlOld = "https://api.semilla.app/"
  export const baseUrl = "https://semilla-prod.s3.us-west-1.amazonaws.com/"
  export const socketUrl = "wss://api.semilla.app/boot-stomp"

export const defaultLanguage="es"
 export const allowedImageTypes = ['image/jpeg', 'image/png','image/jpg'];
 export const allowedDocTypes = ['application/pdf', 'text/csv','application/vnd.openxmlformats-officedocument.wordprocessingml.document',"application/vnd.oasis.opendocument.text","text/plain"];
 export const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

 export const gKey = "AIzaSyA8FESApdFqas53CJfQQ1CP28PsKTPB1tQ"
 export const MyContext = createContext();
 export const UtctolocalDate=(dateTime)=>{
  return moment.utc(dateTime).local()
}
export const errorHandling=(code)=>{
      let currentlang =localStorage.getItem("lang")
    if(code === 401 || code === 403) {
      if(currentlang=="en"){
        swal({ icon: 'error', text: "You are not authorized to access this page. Please Login. ", button: "OK" }).then(() => {
          localStorage.clear()
          localStorage.setItem("lang",currentlang)
          window.location.href = "/login"   
        });
      }else{
        swal({ icon: 'error', text: "No estás autorizado a acceder a esta página. Por favor Iniciar sesión. ", button: "OK" }).then(() => {
          localStorage.clear()
          localStorage.setItem("lang",currentlang)
          window.location.href = "/login"   
        });
      }
      }
  
}
export const  dateFormate = 'DD/MM/YYYY hh:mm A'
export const PasswordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const getStatus = (Otderstatus)=>{
  let status = ""
  if(Otderstatus=="ORDER_INITIATED"){
    status = "Pending"
}else if(Otderstatus=="ORDER_REJECTED"){
    status = "Rejected"

}else if(Otderstatus=="PICKUP_CONFIRMATION_PENDING"){
    status = "Driver Not Assigned"

} else if(Otderstatus=="SEARCHING_DELIVERY_PARTNER"){
    status = "Driver Not Assigned"

}else if(Otderstatus=="PICKUP_CONFIRM"){
    status = "Driver Assigned"

}else if(Otderstatus=="OUT_FOR_DELIVERY"){
    status = "Out For Delivery"

}else if(Otderstatus=="ORDER_CANCELLED"){
    status = "Order Cancelled"

}else if(Otderstatus=="ORDER_DELIVERED"){
    status = "Order Delivered"

}else if(Otderstatus=="ORDER_COMPLETED"){
  status = "Order Completed"

}else if(Otderstatus=="PICKUP_REJECTED"){
    status = "Pickup Rejected"

}else if(Otderstatus=="PICKUP_PARTNER_VERIFIED"){
    status = "Out for Delivery"

}else if(Otderstatus=="AT_DELIVERY_LOCATION"){
    status = "Driver Arriving Shortly"

}else if(Otderstatus=="ORDER_TIMED_OUT"){
  status = "Order Cancelled "
}else
{
    status = Otderstatus
}

return status
}


export let FilterStatus =[
    {
      label:"pending" ,
      value: "ORDER_INITIATED"
    },
    {
        label:"rejected" ,
        value: "ORDER_REJECTED"
      },
      {
        label:"cancelled" ,
        value: "ORDER_CANCELLED"
      },
      {
        label:"out_for-delivery" ,
        value: "OUT_FOR_DELIVERY"
      },
      {
        label:"driver_not_assigned" ,
        value: "SEARCHING_DELIVERY_PARTNER"
      },
      {
        label:"delivered" ,
        value: "ORDER_DELIVERED"
      },
      {
        label:"completed" ,
        value: "ORDER_COMPLETED"
      },
   
     
      
]
export let bankCodes = [
  {
    label:"BOGOTA",
    value:"001"
  },
  {
    label: "POPULAR",
    value: "002"    
  },
  {
    value: "006" ,
    label :"ITAU CORPBANCA"
  },

{ value: "007",    
label :"BANCOLOMBIA"},
{value: "009",    
label :"CITIBANK COLOMBIA"},
{value: "012",     
  label:"GNB SUDAMERIS"},
{value: "014",    
  label :"ITAU"},
{value: "013 ",    
  label :"BBVA"},
{value: "019",     
  label :"SCOTIABANK"},
{value: "023",    
  label :"BANCO DE OCCIDENTE"},
{value: "031"   , 
  label :"BANCOLDEX"},
{value: "032",  
  label :"BCSC"},
{value: "040",     
  label :"BANCO AGRARIO"},
{value: "041",    
  label :"JPMORGAN"},
{value: "042",     
  label :"BNP PARIBAS"},
{value: "047",
label :"MUNDOMUJER"},
{value: "051",
label :"DAVIVIENDA"},
{value: "052",
label : "AV VILLAS"},
{value: "053",
label : "BANCO WWB"}
// {059     BANCAMIA}
// {060     PICHINCHA}
// {061     BANCOOMEVA}
// {062     FALABELLA}
// {063     FINANDINA}
// {065     BCO SANTANDER DE NEGOCIOS}
// {066     COOPCENTRAL}
// {067     COOMPARTIR}
// {069     BANCO SERFINANZA}
// {070     LULO BANK}
// {071     BANCO JP MORGAN COLOMBIA}
// 083     COMPENSAR
// {084     APORTES EN LINEA (GESTION Y CONTACTO)}
// {086     ASOPAGOS}
// {087     FEDECAJAS}
// {088     SIMPLE}
// {089     ENLACE OPERATIVO}
// {090     CORFICOLOMBIANA}
// {121     JURISCOOP}
// {283     COOPERATIVA FINANCIERA DE ANTIOQUIA}
// {289     COTRAFA}
// {292     CONFIAR COOPERATIVA FINANCIERA}
// {370     COLTEFINANCIERA S.A. - COMPANIA DE FINANCIAMIENTO}
// {507     NEQUI}
// {550     DECEVAL}
// {558     CREDIFINANCIERA S.A.}
// {683     DGCPTN}
// {685     DGCPTN-REGALIAS}
// {801     MOVII}
// {1062    BANCO FALABELLA S.A.}
// {1063    BANCO FINANDINA S.A.}
// {1069    BANCO SERFINANZA S.A.}
// {1283    COOPERATIVA FINANCIERA DE ANTIOQUIA}
// {1291    COOFINEP}
// {1292    CONFIAR COOPERATIVA FINANCIERA}
// {1303    GIROS Y FIN ANZAS}
// {1637    IRIS

]


export let docType=[
  {
   value: 'CC',
   label: "Citizenship card"  
  },
 { value:'CE',
 label:"Foreign citizenship card"},
 { value:'NIT',
 label:"Tax identification number"},
 { value:'RC',
 label:"Birth certificate"},
 { value:'TI',
 label:"Identity Card"}
]
 export	const timeRemaining = (createdAt,responseTime)=>{
  console.log("===createdAt====",createdAt)

    const now = new Date()
    const timestamp =  moment.utc(now).local()
    console.log("===currentTime====",timestamp)
  

    // console.log("===dateFromIso====",dateFromIso)
    // const timestamp = currentTime.getTime();
    // console.log("===currentTime====",timestamp)
    const createdAtTime =  moment.utc(createdAt).local()
   
    console.log("===createdAtTime====",createdAtTime)
    const totalDuration = responseTime * 60 * 1000;

    const timeElapsed = timestamp -createdAtTime ;
    console.log("===timeElapsed====",timeElapsed)
    const minutes = timeElapsed / 60000;
    console.log("===minutes====",minutes)
    console.log("===totalDuration====",totalDuration/ 60000)
    let elapsedTime; 
    if (timeElapsed >= totalDuration) {
      elapsedTime = totalDuration
    } else {
      elapsedTime = timeElapsed/60000
     
    }
    const remainingTime = totalDuration - elapsedTime;
    console.log("===remainingTime====",remainingTime)
    return remainingTime
  }
  export const formatToColombianCurrency = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };
// public static final String REJECTED = "ORDER_REJECTED";
// public static final String CONFIRMED = "CONFIRMED";
// public static final String SEARCHING_DELIVERY_PARTNER = "SEARCHING_DELIVERY_PARTNER";
// public static final String PICKUP_CONFIRMATION_PENDING = "PICKUP_CONFIRMATION_PENDING";
// public static final String PICKUP_REJECTED = "PICKUP_REJECTED";
// public static final String PICKUP_CONFIRM = "PICKUP_CONFIRM";
// public static final String PICKUP_PARTNER_VERIFIED = "PICKUP_PARTNER_VERIFIED";
// public static final String OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY";
// public static final String AT_DELIVERY_LOCATION = "AT_DELIVERY_LOCATION";
// public static final String DELIVERED = "ORDER_DELIVERED";
// public static final String CANCELLED = "ORDER_CANCELLED";
