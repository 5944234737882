import { Container, Row } from "react-bootstrap";
import { useTranslation, initReactI18next } from 'react-i18next';
import React, { useEffect, useRef,useState } from "react";
import { useLocation, useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import '../i18n';
import TabComponent from "../common/TabComponent";
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const contentRef = useRef();
  const [content, setContent] = useState("");

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  

  useEffect(() => {
    const timer = setTimeout(() => {
      //privacy policy for cultivator app
      if (source === 'cultivator') {
        const containerContent = contentRef.current.innerHTML;
        const updatedContent = containerContent.replace(/Semilla/g, "Fruvers");
        setContent(updatedContent);
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [source]); 

  useEffect(() => {
    if (content && contentRef.current) {
      contentRef.current.innerHTML = content;
    }
  }, [content]);

  return (
    <>
      <div className="height-footer privacy-policy-section">
        <Container>
          <Row className="justify-content-center mt-3">
          <div ref={contentRef}>
            <h2>{t("privacy_policy")}</h2>
            <p className="mt-2">{t("authorize")}</p>
            <div className="privacy-txt"> 
              {/* <h4>1. Types of Information Collected and Stored</h4> */}
              <p>{t("pp_p1")}</p>
            </div>
            <div className="privacy-txt">
              {/* <h4>2. Analytics Information</h4> */}
              <p>{t("pp_p2")}</p>  </div>
            <div className="privacy-txt">
              {/* <h4>3. Analytics Information</h4> */}
              <p>{t("pp_p3")}</p>  
            </div>
            <div className="privacy-txt">
              <h4>{t("pp_p4_head")}</h4>
              <p>{t("pp_p4")}</p> 
              <ul>
                <li>
                {t("pp_p4_li1")}
                </li>
                <li>
                {t("pp_p4_li2")}
                </li>
                <li>
                {t("pp_p4_li3")}
                </li>
                <li>
                {t("pp_p4_li4")}
                </li>
                <li>
                {t("pp_p4_li5")}
                </li>
                <li>
                {t("pp_p4_li6")}
                </li>
                <li>
                {t("pp_p4_li7")}
                </li>
                <li>
                {t("pp_p4_li8")}
                </li>
                <li>
                {t("pp_p4_li9")}
                </li>
                <li>
                {t("pp_p4_li10")}
                </li>
                <li>
                {t("pp_p4_li11")}
                </li>
                <li>
                {t("pp_p4_li12")}
                </li>
                <li>
                {t("pp_p4_li13")}
                </li>
                <li>
                {t("pp_p4_li14")}
                </li>
              </ul>
                </div>
            <div className="privacy-txt">
              {/* <h4>4. Analytics Information</h4> */}
              <p>{t("pp_p5")}</p>
              <ul>
                <li>
                {t("pp_p5_li1")}
                </li>
                <li>
                {t("pp_p5_li2")}
                </li>
                <li>
                {t("pp_p5_li3")}
                </li>
                <li>
                {t("pp_p5_li4")}
                </li>
                <li>
                {t("pp_p5_li5")}
                </li>
              </ul>
              </div>
              <div className="privacy-txt">
              {/* <h4>4. Analytics Information</h4> */}
              <p>{t("pp_p6")}</p>  </div>
              <div className="privacy-txt">
              {/* <h4>4. Analytics Information</h4> */}
              <p>{t("pp_p7")}</p>  
              <ul className="privacy-txt-li">
                <li>{t("pp_li1")}</li>
                <li>{t("pp_li2")}</li>
                <li>{t("pp_li3")}</li>
              </ul>
              <p>{t("pp_endline")}</p>  
              </div>
          </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default PrivacyPolicy;
